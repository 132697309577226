<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-email"
                name="E-mail"
                label="E-mail"
                type="E-mail"
                v-model="form.email"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="form.password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submit()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { store } from "../store";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          data;
          const user = firebase.auth().currentUser;
          store.commit("authenticated", true);
          localStorage.setItem("userID", user.uid);
          this.$router.replace({ name: "InputPage" });
        //   return firebase
        //     .auth()
        //     .signInWithEmailAndPassword(this.form.email, this.form.password);
        })
        .catch((err) => {
          alert(err.message); //TODO make snackbar
          this.error = err.message;
        });
    },
  },
};
</script>
